@import url("https://fonts.googleapis.com/css?family=Bree+Serif|Roboto&display=swap");
html {
	width: 100%;
}

a {
	cursor: pointer;
}

h1 {
	font-family: "Bree Serif", serif;
	font-size: min(5vw, 60px);
}

h2 {
	font-family: "Bree Serif", serif;
	font-size: 42px;
}

h3 {
	font-family: "Bree Serif", serif;
	font-size: 24px;
	color: #ef3e31;
}

h4 {
	font-family: "Bree Serif", serif;
	font-size: 24px;
	color: #ef3e31;
}

video {
	width: 80vw;
	height: 45vw;
}

.selectFont {
	font-family: "Bree Serif", serif;
}

#selectColorCNTSS {
	color: white;
}

.footer {
	padding-top: 50px;
	padding-bottom: 50px;
	font-family: "Roboto", sans-serif;
	margin: auto;
}

.justify-content-center {
	display: flex;
}

#footerText {
	padding-top: 10px;
	font-size: 25pt;
}

#footerText p {
	margin: 0;
	font-size: 18px;
}

#footerLogo {
	width: 316px;
	padding-right: 20px;
	border-right: 2px rgba(6, 20, 77, 0.6) solid;
	margin-right: 20px;
}

@media (max-width: 900px) {
	#footerLogo {
		display: none;
	}
}

#SSlogo {
	width: 160px;
	margin-left: 12px;
	height: auto;
}

#SSBrand {
	height: 35%;
	width: 35%;
	margin-left: 5%;
}

#menubar {
	position: relative;
	top: 0px;
	width: 100%;
	margin: auto;
}

#menubar li {
	color: #06144d;
	cursor: pointer;
}

#menubar a {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	text-decoration: none;
	color: #06144d;
}

#menubar a:hover {
	color: #ef3e31;
}

#menubar .navbar-nav a {
	font-size: 18px;
}

@media (max-width: 970px) {
	.nav {
		display: none;
	}
}

@media (min-width: 970px) {
	.navbar-nav {
		display: none;
	}
}

#greetingBox {
	position: relative;
	height: 60%;
	background-color: #06144d;
}

.maxWidth {
	max-width: 1200px;
	margin-left: auto !important;
	margin-right: auto !important;
	float: none !important;
}

#greetingBoxContent {
	padding-left: 15%;
}

#greetingBoxText {
	color: #ef3e31;
	width: 45%;
	float: left;
	padding-top: 9%;
	margin-right: 5%;
	height: 100%;
}

#middleTextCNT {
	font-family: "Bree Serif", serif;
	color: #ef3e31;
	font-size: 24px;
	width: 70%;
	padding-top: 4%;
	padding-bottom: 3%;
	margin-left: 15%;
	position: relative;
}

#selectedColorCNTMid {
	color: #06144d;
}

#previewContent {
	width: 100%;
	position: relative;
}

.previewContentSide {
	position: static;
	float: left;
	width: 50%;
	height: auto;
	cursor: pointer;
	border-radius: 5px;
	overflow: hidden;
}

.previewContentSide img {
	width: 100%;
	border-radius: 0px;
}

.previewText {
	font-family: "Bree Serif", serif;
	background-color: #06144d;
	padding: 1%;
	width: 100%;
	color: white;
	text-decoration: none;
	font-size: 24px;
	background-color: #ef3e31;
}

#previewTextGallery {
	background-color: #06144d;
}

#playButton {
	position: absolute;
	top: min(12vw, 144px);
	left: 24%;
	width: 5%;
}

#illustrationContent {
	margin-top: 6%;
	width: 100%;
	height: 20%;
	float: left;
}

#editContent {
	width: 35%;
	margin-left: 13%;
	float: left;
}

#SSImediately {
	width: 35%;
	margin-left: 3%;
}

@media (max-width: 1200px) {
	#editContent {
		float: none;
		width: 55%;
		margin-left: 20%;
	}
}

@media (max-width: 1200px) {
	#SSImediately {
		float: none;
		width: 55%;
		margin-left: 20%;
	}
}

#editContent p, #SSImediately p {
	font-size: 18px;
	font-family: "Roboto", sans-serif;
}

@media (max-width: 1200px) {
	#editContent p, #SSImediately p {
		font-size: 22px;
	}
}

#editIllustration, #SSIllustration {
	width: 35%;
	height: 45%;
	margin-right: 5%;
}

@media (max-width: 1200px) {
	#editIllustration, #SSIllustration {
		width: 30%;
		margin-bottom: 2%;
	}
}

#pwcPreview {
	width: 35%;
}

#goldenAgePreview {
	width: 30%;
}

#cityGalleryPreview {
	width: 35%;
}

#imagePreviewContent {
	width: 100%;
	height: 50%;
	margin-bottom: 5%;
	position: relative;
	float: left;
	margin-top: 5%;
}

#features {
	width: 100%;
	padding: 15%;
	padding-bottom: 5%;
}

#features h4 {
	color: #ef3e31;
	border-bottom: 0.3vw #ef3e31 solid;
	margin-bottom: 2%;
	padding-bottom: 1%;
}

#features ul {
	columns: 2;
}

#features li {
	font-size: 18px;
	padding-right: 15%;
	font-family: "Roboto", sans-serif;
}

@media (max-width: 850px) {
	#features ul {
		columns: 1;
	}
	#features li {
		font-size: 18px;
	}
}

#interested {
	background-color: #ef3e31;
	padding: 5% 15%;
}

#interested h4 {
	color: white;
	margin-bottom: 4%;
}

#interested button {
	color: white;
	font-family: "Bree Serif", serif;
	font-size: 22px;
	height: 12%;
	padding: 0px 35px;
	border: 3px white solid;
	border-radius: 40px;
	background-color: transparent;
}

#interested button a {
	color: white;
	text-decoration: none;
}

#contact, #caseTitle {
	color: white;
	background-color: #06144d;
	padding-top: 10px;
	padding-bottom: 15px;
	margin-bottom: 20px;
}

#details {
	position: relative;
	left: 8%;
	width: 86%;
}

#questionsText, #detailsText {
	float: left;
	margin-bottom: 10px;
}

#questionsText {
	font-family: "Bree Serif", serif;
	font-size: 21px;
	color: #ef3e31;
	width: 46%;
}

@media (max-width: 900px) {
	#questionsText {
		width: 100%;
		font-size: 18px;
	}
	#detailsText {
		display: none;
	}
}

#detailsText {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	width: 44%;
	margin-left: 10%;
}

#footerText a, #detailsText a {
	color: inherit;
}

#footerText a:hover, #detailsText a:hover {
	color: #ee3c31;
}

#questionsText p, #detailsText p {
	margin-bottom: 0;
}

input[type=text] {
	width: 85%;
	padding-left: 1%;
	margin: 8px 0;
	box-sizing: border-box;
	font-size: 18px;
	background-color: #f3f3f3;
	font-family: "Roboto", sans-serif;
	border: none;
}

.configForm input {
	width: 300px;
	margin-right: 50px;
}

input::placeholder {
	font-family: "Bree Serif", serif;
	color: black !important;
}

input:focus {
	outline: 0;
}

.mobileLInput {
	height: 70px;
}

@media (min-width: 525px) and (max-width: 850px) {
	.mobileLInput {
		height: 55px;
	}
}

@media (max-width: 525px) {
	.mobileLInput {
		height: 44px;
	}
}

.standardButton {
	color: #ef3e31;
	font-family: "Bree Serif", serif;
	font-size: 18px;
	padding: 0px 35px;
	margin: auto;
	border: 2px #ef3e31 solid;
	border-radius: 36px;
	background-color: transparent;
}

#button {
	color: #ee3c31 !important;
	border: 2px solid #ee3c31;
	border-radius: 36px;
	margin: 6px 0px 1px 12px;
}

.standardButton:hover, #button:hover {
	color: #fff !important;
	background-color: #ee3c31 !important;
	box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.2), 0 6px 20px -1px rgba(0, 0, 0, 0.19);
}

button:focus {
	outline: 0;
}

textarea {
	width: 85%;
	margin-bottom: 3%;
	margin-top: 1%;
	padding-left: 1%;
	margin: 8px 0;
	box-sizing: border-box;
	font-size: 18px;
	background-color: #f3f3f3;
	font-family: "Roboto", sans-serif;
	border: none;
}

textarea::placeholder {
	font-family: "Bree Serif", serif;
	color: black !important;
}

textarea:focus {
	outline: 0;
}

#laundromatCase {
	width: 25%;
	float: left;
	margin-left: 25%;
}

#laundromatCase:hover {
	cursor: pointer;
}

@media (max-width: 525px) {
	#laundromatCase {
		width: 74%;
		margin-left: 17%;
		margin-bottom: 2%;
	}
}

#goldenCase {
	width: 25%;
	margin-left: 25%;
}

#goldenCase:hover {
	cursor: pointer;
}

@media (max-width: 525px) {
	#goldenCase {
		width: 74%;
		margin-left: 17%;
		margin-bottom: 2%;
	}
}

.row {
	margin: 24px 0px;
}

@media (max-width: 767px) {
	.col-md-4 {
		margin-bottom: 5vw;
	}
}

#cityCase {
	width: 24%;
	margin-left: 2%;
}

#cityCase:hover {
	cursor: pointer;
}

@media (max-width: 525px) {
	#cityCase {
		width: 74%;
		margin-left: 17%;
	}
}

.caseSize {
	width: 30%;
	margin: 1%;
}

#laundroCase1 {
	margin-left: 20%;
}

#laundroCase3 {
	margin-left: 20%;
	margin-bottom: 2%;
}

#laundroCase4 {
	margin-bottom: 2%;
}

.caseButton {
	margin: 0%;
	border: none;
	background-color: transparent;
	font-size: 16pt;
}

#cityCase1 {
	margin-left: 20%;
}

#galleryCase1 {
	margin-left: 20%;
}

#galleryCase3 {
	margin-left: 20%;
	margin-bottom: 2%;
}

#galleryCase4 {
	margin-bottom: 2%;
}

area:focus {
	outline: 0;
}

#hamburger {
	display: none;
}

@media (max-width: 970px) {
	#hamburger {
		display: initial;
	}
}

.navbar .navbar-toggler-icon {
	width: 1em;
	height: 1em;
}

.navbar-brand {
	float: none;
	padding: 5px;
}

.navbar-center {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	text-align: center;
	margin: auto;
	height: 100%;
}

.navbar-center:hover {
	color: #ef3e31;
}

.configurator {
	position: relative;
	width: 1220px;
	margin: auto;
	font-family: "Roboto", sans-serif;
	color: #333;
}

.wall-pane {
	position: relative;
	width: 230px;
	float: left;
	text-align: left;
	margin: 0px;
}

.wall-pane hr {
	margin: 5px;
}

.wall-pane-section {
	font-size: 16px;
	padding: 5px 10px;
}

.wall-pane-section input, .wall-pane-section .outputs {
	margin: 0px 5px 0px 10px;
}

input[type='number'] {
	width: 50px;
}

.wall-preview {
	position: relative;
	width: 740px;
	float: left;
}

.wall-image {
	position: relative;
	width: 100%;
	height: 0px;
	padding-bottom: 60.46%;
	background-size: cover;
}

.wall-displays {
	position: absolute;
	box-shadow: 12px 8px 5px 0px #a8a4a0, 2px 2px 10px -5px #455263;
}

.wall-bezels {
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 185px;
	height: 185px;
	background-size: cover;
	border: solid 2px black;
	border-radius: 92.5px;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
}

.wallDisclosure {
	position: absolute;
	height: 25px;
	width: 300px;
	bottom: 10px;
	left: 180px;
}

.display-image, .display-video {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.display-grid {
	position: absolute;
	top: 0px;
	left: 0px;
	background-size: cover;
	background-position: center center;
}

.display-example {
	width: 99px;
	height: 55.5px;
	float: left;
}

.bezel-example {
	width: 104.83px;
	height: 104.83px;
	float: left;
}

#emailPDF {
	margin-top: 20px;
}

.configuratorWarning {
	color: #ee3c31;
	font-size: 14px;
}

.wallWidth {
	position: absolute;
	height: 24.66666666666666px;
	border-bottom: solid 1px #333;
	font-size: 14px;
}

.wallHeight {
	position: absolute;
	width: 100px;
	margin-left: 10px;
	border-left: solid 1px #333;
	font-size: 14px;
	padding-left: 8px;
}